import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MainHeader from "./MainHeader";

const Main = ({ login }) => {

    return <>

        <MainHeader login={login} />

        <div className="fixed inset-0">

            <div className="w-full h-full" style={{ background: "url(/images/header.jpg) 50%", backgroundSize: "cover" }}>

                {!login && <div className="bg-gradient-to-b from-zinc-900 flex justify-center items-center" style={{ height: "100%", width: "100%" }}>

                    <div className="text-center text-slate-100">

                        <div className="flex flex-col lg:flex-row justify-center items-center mb-3">
                            <img
                                src="/logo192.jpg"
                                alt="kolgaev.ru"
                                className="w-20 h-20 rounded lg:mr-4 shadow-lg shadow-white/20"
                            />
                            <h1 className="text-5xl lg:text-7xl text-white font-mono m-0">kolgaev.ru</h1>
                        </div>

                    </div>

                </div>}

            </div>

            {!login && <Links />}

        </div>

    </>
}

export const Links = () => {
    return <div className="absolute flex justify-center items-center w-full bottom-0 pt-5 pb-2 bg-gradient-to-t from-zinc-900 text-white">
        <a className="opacity-60 hover:opacity-100 text-white hover:text-white cursor-pointer mx-1" href="https://l.kolgaev.ru/1" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={['fab', 'github']} size="2x" />
        </a>
        <a className="opacity-60 hover:opacity-100 text-white hover:text-white cursor-pointer mx-1" href="https://l.kolgaev.ru/2" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={['fab', 'gitlab']} size="2x" />
        </a>
        {/* <a className="opacity-60 hover:opacity-100 text-white hover:text-white cursor-pointer mx-1" href="https://l.kolgaev.ru/3" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={['fab', 'instagram']} size="2x" />
        </a> */}
        <a className="opacity-60 hover:opacity-100 text-white hover:text-white cursor-pointer mx-1" href="https://l.kolgaev.ru/4" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={['fab', 'vk']} size="2x" />
        </a>
        <a className="opacity-60 hover:opacity-100 text-white hover:text-white cursor-pointer mx-1" href="https://l.kolgaev.ru/5" target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={['fab', 'youtube']} size="2x" />
        </a>
    </div>
}

export default Main;